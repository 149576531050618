

window.addEventListener('load', (event) => {
    let openButton = document.querySelector(".open-menu-button");
    let closeButton = document.querySelector(".close-menu-button");
    let closeZone = document.querySelector(".background_trigger.menu");
    let navigation = document.getElementById("navigation");
    let nav = document.querySelector("#main-menu");
    let body = document.querySelector("body");

    if (openButton) {
        openButton.onclick = function toggleMenu() {
            body.style.overflow = "hidden";
            //body.style.position = "fixed";
            nav.classList.add("show");
            setTimeout(function () {
                navigation.classList.remove("hidden");
                nav.classList.add("animate-show");
            }, 100);
        }
    }

    if (closeZone) {
        closeZone.addEventListener('click', function (event) {
            nav.classList.remove("animate-show");
            body.style.overflow = "visible";
            setTimeout(function () {
                nav.classList.remove("show");
            }, 400);
        });
    }

    if (closeButton) {
        closeButton.onclick = function toggleMenu() {
            body.style.overflow = "visible";
            nav.classList.remove("animate-show");
            setTimeout(function () {
                nav.classList.remove("show");
            }, 400);
        }
    }
});