import { loadMore } from './_load-more-posts.js';

let allPostsContainer = document.querySelector(".all-posts");
let cursor = document.querySelector("#cursor");
let posts = null;
let filters = null;
let activeFilter = null;
let moreButton = null;

if(allPostsContainer) {
    posts = document.querySelectorAll(".tease");
    filters = document.querySelectorAll(".filter-list p");
    moreButton = document.querySelector('.more-posts');
    
    filters.forEach(filter => {
        if(filter.classList.contains("active"))
            activeFilter = filter;

        filter.addEventListener('click', function(e) {
            moreButton.classList.add('hide');
            filterPosts(filter);
            loadMore();
        });
    });

    posts.forEach(post => {
        post.addEventListener('mouseover', function(e) {
            postMouseOver(post);
        });

        post.addEventListener('mouseout', function(e){
            postMouseOut();
        });
    });

    moreButton.addEventListener("click", function(e){
        posts = document.querySelectorAll(".tease");
        
        posts.forEach(post => {
            post.addEventListener('mouseover', function(e) {
                postMouseOver(post);
            });
    
            post.addEventListener('mouseout', function(e){
                postMouseOut();
            });
        });
    });
}

export function filterPosts(filter) {
    const activeCounter = document.querySelector('.active-filter-count');
    activeCounter.dataset.totalposts = 0;
    activeCounter.dataset.category = filter.dataset.name || '';
    document.querySelector('.all-posts').classList.add('clear');
    document.querySelector('.data-offset').dataset.offset = 0;
    
    if(filter.classList.contains("active"))
        activeFilter = filter;

    if(activeFilter)
        activeFilter.classList.remove('active');
    
    posts = document.querySelectorAll(".tease");
    filter.classList.add("active");
    activeFilter = filter;
    
    activeCounter.innerHTML = 'Loading...';
}

export function postMouseOver(post) {
    let postCategory = post.querySelector('.category');
    let hovertext = postCategory.innerHTML + ' ';
    appendCursorText(cursor, hovertext);
}

export function postMouseOut() {
    removeCursorText(cursor);
}

function appendCursorText(cursor, txt) {
    let textBox = document.createElement("p");
    textBox.innerText = 'Read ' + txt;
    textBox.classList.add('cursor-text');
    
    cursor.innerHTML = '';
    cursor.append(textBox);
    
    let width = textBox.offsetWidth + 45;
    cursor.style.width = width+'px';
    cursor.style.left = '-60px';

    cursor.classList.add('active');
}

function removeCursorText(cursor) {
    cursor.innerHTML = '';
    cursor.style.width = '18px'
    cursor.classList.remove('active');
}