let cursor = document.querySelector("#cursor");
let pageList = document.querySelector('.page-list');

if(pageList) {
    pageItems = pageList.querySelectorAll('.page');

    for (let i = 0; i < pageItems.length; i++) {
        let pageItem = pageItems[i];
        pageItem.addEventListener('mouseover', function(event) {
            let hovertext = ''; 
            if(pageItem.dataset.hovertext == '') {
                hovertext = 'Read more ↗';    
            } else {
                hovertext =pageItem.dataset.hovertext +' ↗';
            }
            appendCursorText(cursor, hovertext);
        });

        pageItem.addEventListener('mouseout', function(event) {
            removeCursorText(cursor);
        });
    };
}

function appendCursorText(cursor, txt) {
    let textBox = document.createElement("p");
    textBox.innerText = txt;
    textBox.classList.add('cursor-text');
    
    cursor.innerHTML = '';
    cursor.append(textBox);
    
    let width = textBox.offsetWidth + 45;
    cursor.style.width = width+'px';
    cursor.style.left = '-60px';

    cursor.classList.add('active');
}

function removeCursorText(cursor) {
    cursor.innerHTML = '';
    cursor.style.width = '18px'
    cursor.classList.remove('active');
}