let cursor = document.querySelector("#cursor");
let boxesSection = document.querySelector('.reveal-boxes');

if(boxesSection) {
    let boxes = boxesSection.querySelectorAll('.box');

    for(let i=0;i<boxes.length;i++) {
        boxes[i].addEventListener('click', function(event) {
            if(boxes[i].classList.contains('active')) {
                boxes[i].classList.remove('active');
            } else {
                boxes[i].classList.add('active');
            }
        });

        let links = boxes[i].querySelectorAll('.link');
        for(let y=0;y<links.length;y++) {
            links[y].addEventListener('click', function(event) {
                setTimeout(function() {
                    boxes[i].classList.add('active');
                }, 1);
            });
        }
    }

}