import { moveGlide } from './_innline-image-slider-configuration.js';

let slider = document.querySelector('#post-inline-image-slider');
let cursor = document.querySelector("#cursor");
let sliderItems = null;


if(slider) {    
    sliderItems = slider.querySelectorAll('.glide__slide');
    let sliderContent = slider.querySelectorAll('.slider-content');
    let sliderImg = slider.querySelectorAll('.slider-image');
    let fieldNext = slider.querySelectorAll(".cursor-next-field");
    let fieldPrev = slider.querySelectorAll(".cursor-prev-field");
    let imageLightBox = document.querySelectorAll('.image-light-box');
    let exitLightbox = document.querySelectorAll(".exit-background");
    let header = document.querySelector('header');
    let body = document.querySelector("body");
    let leftArrow = document.querySelectorAll(".left-arrow")
    let rightArrow = document.querySelectorAll(".right-arrow")

    let totalSlides = fieldNext.length;

    for(let i=0; i<leftArrow.length;i++) {
        leftArrow[i].addEventListener('click', function(event) {
            imageLightBox[i-1].classList.add("active");
            setTimeout(() => {                
                imageLightBox[i].classList.remove("animate-show");
                imageLightBox[i-1].classList.add("animate-show");
            }, 10);
            setTimeout(() => {
                imageLightBox[i].classList.remove("active");
            }, 300);
        });

        rightArrow[i].addEventListener('click', function(event) {
            imageLightBox[i+1].classList.add("active");
            setTimeout(() => {                
                imageLightBox[i].classList.remove("animate-show");
                imageLightBox[i+1].classList.add("animate-show");
            }, 10);
            setTimeout(() => {
                imageLightBox[i].classList.remove("active");
            }, 300);
        });
    }

    for (let i = 0; i < sliderItems.length; i++) {

        sliderImg[i].addEventListener('click', function(event) {
            imageLightBox[i].classList.add("active");
            body.style.overflow = "hidden";
            header.classList.add("scroll-down", 'pale-green-logo');
            setTimeout(() => {
                imageLightBox[i].classList.add("animate-show");
            }, 10);
        });

        exitLightbox[i].addEventListener('click', function(event) {
            imageLightBox[i].classList.remove("animate-show");
            header.classList.remove("pale-green-logo");
            setTimeout(() => {
                imageLightBox[i].classList.remove("active");
                body.style.overflow = "visible";
            }, 300);
        });


        sliderImg[i].addEventListener('mouseover', function(event) {
            let txt = 'Enlarge image +';
            appendCursorField(txt, cursor)
        });

        sliderImg[i].addEventListener('mouseout', function(event) {
            removeCursorField(sliderItems[i], cursor)
        });

        fieldNext[i].addEventListener('mouseover', function(event) {
            let txt = 'Next →';
            appendCursorField(txt, cursor)
        });

        fieldNext[i].addEventListener('mouseout', function(event) {
            removeCursorField(fieldNext[i], cursor)
        });

        fieldPrev[i].addEventListener('mouseover', function(event) {
            let txt = '← Prev';
            appendCursorField(txt, cursor)
        });

        fieldPrev[i].addEventListener('mouseout', function(event) {
            removeCursorField(fieldNext[i], cursor)
        });

        fieldNext[i].addEventListener('click', function(event) {
            moveGlide('>');
        });
    
        fieldPrev[i].addEventListener('click', function(event) {
            moveGlide('<');
        });
    };
}

function appendCursorField(txt, cursor) {
    cursor.innerHTML = '';
    let textBox = document.createElement("p");
    textBox.classList.add('cursor-text');
    textBox.innerText = txt;    
    cursor.append(textBox);

    let width = textBox.offsetWidth + 45;
    cursor.style.width = width+'px';
    cursor.style.left = '-60px';

    cursor.classList.add('active');
}

function removeCursorField(field, cursor) {
    cursor.innerHTML = '';
    cursor.style.width = '18px'
    cursor.classList.remove('active');
}
