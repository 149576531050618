import { postMouseOver, postMouseOut, filterPosts } from './_post-archive-filters.js';
import { intersectionObserve } from "./_intersection-observer";
const news =  document.querySelectorAll('.all-posts article.tease');
const moreButton = document.querySelector('.more-posts');
const moreButtons = document.querySelectorAll('.more-posts');

function loadMore() {
    const activeFilterCount = document.querySelector('.active-filter-count');
    const dataOffset = document.querySelector('.data-offset');
    const offset = Number(dataOffset.dataset.offset); //Controls the offset on which articles to load

    if (offset > Number(activeFilterCount.dataset.totalposts)) {
        return;
    }

    const selectedPosts = document.querySelector('.post-archive').dataset.stickyPosts; //ID of selected posts to pin in post archive block.
    const filters = document.querySelectorAll(".filter-list p");
    const currentCategory = Array.from(filters).filter(filter => filter.classList.contains("active"))[0].dataset.slug;

    let data = new FormData();
    data.append('action', 'get_news');
    data.append('offset', offset);
    data.append('selected_posts', selectedPosts);
    data.append('category', currentCategory || '');
    $.ajax({
        type: 'POST',
        url: sevenanalytics_scripts.ajax_url,
        cache: false,
        processData: false,
        contentType: false,
        data: data,
        success: function (appendedNews) {
            const allPostsContainer = document.querySelector('.all-posts');

            // Clear posts container if "clear" class is set
            if (allPostsContainer.classList.contains('clear')) {
                allPostsContainer.classList.remove('clear');
                allPostsContainer.innerHTML = '';
            }

            // Store the current length of posts
            const prevLength = document.querySelectorAll('.all-posts .tease').length;

            // Append new posts
            $('.all-posts').append(appendedNews);

            // Update offset for next loading
            const allPosts = $('.all-posts .tease').slice(prevLength);
            const posts = Array.from(allPosts);
            const newOffset = Number(offset) + posts.length; //Increase the offset by the numbers of articles fetch per page.
            dataOffset.dataset.offset = newOffset;

            // Store new total count
            const totalCount = document.querySelector('.all-posts').querySelector('.posts-total').dataset.total;
            activeFilterCount.dataset.totalposts = totalCount;

            // Update category label and count
            activeFilterCount.innerHTML = (activeFilterCount.dataset.category || 'All') + ' (' + activeFilterCount.dataset.totalposts + ')';

            // Add mouse listeners to new posts
            posts.forEach(post => {
                post.addEventListener('mouseover', function(e) {
                    postMouseOver(post);
                });
        
                post.addEventListener('mouseout', function(e){
                    postMouseOut();
                });
            });

            // Show or hide "Load more" button as needed
            if (Number(dataOffset.dataset.offset) < totalCount) {
                moreButton.classList.remove('hide');
            } else {
                moreButton.classList.add('hide');
            }
        }
    });
}

if(news && news.length > 0 && moreButton) {    
    intersectionObserve(moreButtons, 0, 0, (moreButtons, intersectionRatio) => {
        (function(el) {
            if (intersectionRatio > 0 && !moreButton.classList.contains("hide")) {
                loadMore();
            } 
        })(moreButtons);
    });
}

export { loadMore };