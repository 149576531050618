import { moveGlide1, moveGlide2 } from './_office-info-slider-configuration.js';
let slider1 = document.querySelector("#slider-location-1");
let slider2 = document.querySelector("#slider-location-2");
let cursor = document.querySelector("#cursor");
let sliderItems = null;

if(slider1) {
    sliderItems = slider1.querySelectorAll('.glide__slide');
    let sliderImages = slider1.querySelectorAll('.image');

    let fieldNext = slider1.querySelectorAll(".cursor-next-field");
    let fieldPrev = slider1.querySelectorAll(".cursor-prev-field");
    let totalSlides = fieldNext.length;

    for (let i = 0; i < sliderItems.length; i++) {

        fieldNext[i].addEventListener('mouseover', function(event) {
            //let txt = i+1 + '/' + totalSlides + ' →';
            let txt = 'Next →';
            appendCursorField(txt, cursor)
        });

        fieldNext[i].addEventListener('mouseout', function(event) {
            removeCursorField(fieldNext[i], cursor)
        });

        fieldPrev[i].addEventListener('mouseover', function(event) {
            //let txt = '← ' + (i+1) + '/' + totalSlides;
            let txt = '← Prev';
            appendCursorField(txt, cursor)
        });

        fieldPrev[i].addEventListener('mouseout', function(event) {
            removeCursorField(fieldNext[i], cursor)
        });

        fieldNext[i].addEventListener('click', function(event) {
            moveGlide1('>');
        });

        fieldPrev[i].addEventListener('click', function(event) {
            moveGlide1('<');
        });
    };
}

if(slider2) {
    sliderItems = slider2.querySelectorAll('.glide__slide');
    let sliderImages = slider2.querySelectorAll('.image');

    let fieldNext = slider2.querySelectorAll(".cursor-next-field");
    let fieldPrev = slider2.querySelectorAll(".cursor-prev-field");
    let totalSlides = fieldNext.length;


    for (let i = 0; i < sliderItems.length; i++) {

        fieldNext[i].addEventListener('mouseover', function(event) {
            //let txt = i+1 + '/' + totalSlides + ' →';
            let txt = 'Next →';
            appendCursorField(txt, cursor)
        });

        fieldNext[i].addEventListener('mouseout', function(event) {
            removeCursorField(fieldNext[i], cursor)
        });

        fieldPrev[i].addEventListener('mouseover', function(event) {
            //let txt = '← ' + (i+1) + '/' + totalSlides;
            let txt = '← Prev';
            appendCursorField(txt, cursor)
        });

        fieldPrev[i].addEventListener('mouseout', function(event) {
            removeCursorField(fieldNext[i], cursor)
        });

        fieldNext[i].addEventListener('click', function(event) {
            moveGlide2('>');
        });

        fieldPrev[i].addEventListener('click', function(event) {
            moveGlide2('<');
        });
    };
}

function appendCursorField(txt, cursor) {
    cursor.innerHTML = '';
    let textBox = document.createElement("p");
    textBox.classList.add('cursor-text');
    textBox.innerText = txt;    
    cursor.append(textBox);

    let width = textBox.offsetWidth + 45;
    cursor.style.width = width+'px';
    cursor.style.left = '-60px';

    cursor.classList.add('active');
}

function removeCursorField(field, cursor) {
    cursor.innerHTML = '';
    cursor.style.width = '18px'
    cursor.classList.remove('active');
}