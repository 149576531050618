let cursor = document.querySelector("#cursor");
let employeesSection = document.querySelector('.employees');

if(employeesSection) {
    let button = employeesSection.querySelector('.link');
    let employees = employeesSection.querySelectorAll('.employee.hide');
    let showing = false;

    button.addEventListener('click', function(event) {
        if(showing == false){
            showing = true
        } else {
            showing = false
        }

        for(let i=0;i<employees.length;i++) {
            if(showing) {
                employees[i].classList.remove('hide')
                button.classList.add('less')
                button.querySelector('p').innerText = 'Hide';
                setTimeout(function() {
                    employees[i].classList.remove('opacity-null')
                }, 100);
            } else {
                employees[i].classList.add('opacity-null')
                button.classList.remove('less')
                button.querySelector('p').innerText = 'View all';
                setTimeout(function() {
                    employees[i].classList.add('hide')
                }, 300);
            }
        }
    });

}