import Glide from "@glidejs/glide";
let glide = null;

function initPartnerSlider(id) {
	glide = new Glide("#" + id, {
		type: "carousel",
		bound: true,
		startAt: 0,
		gap: 30,
		perView: 5,
		perSwipe: "|",
		breakpoints: {
			900: {
				perView: 2,
				gap: 0,
			},
		},
	});

	glide.mount();
}

export function moveGlide(whichWay) {
	glide.go(whichWay);
}

window.initPartnerSlider = initPartnerSlider;
