let singlePage = document.querySelector(".single-post");
let cursor = document.querySelector("#cursor");

if(singlePage) {
    let posts = singlePage.querySelectorAll(".tease");

    posts.forEach(post => {
        post.addEventListener('mouseover', function(e) {
            postMouseOver(post);
        });

        post.addEventListener('mouseout', function(e){
            postMouseOut();
        });
    });
}

function postMouseOver(post) {
    let postCategory = post.querySelector('.category');
    let hovertext = postCategory.innerHTML +' ↗';
    appendCursorText(cursor, hovertext);
}

function postMouseOut() {
    removeCursorText(cursor);
}

function appendCursorText(cursor, txt) {
    let textBox = document.createElement("p");
    textBox.innerText = 'Read ' + txt;
    textBox.classList.add('cursor-text');
    
    cursor.innerHTML = '';
    cursor.append(textBox);
    
    let width = textBox.offsetWidth + 45;
    cursor.style.width = width+'px';
    cursor.style.left = '-60px';

    cursor.classList.add('active');
}

function removeCursorText(cursor) {
    cursor.innerHTML = '';
    cursor.style.width = '18px'
    cursor.classList.remove('active');
}