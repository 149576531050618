const cursor = document.querySelector("#cursor");
const referrralList = document.querySelector(".referral-list");
let currentActive = 0;

if(referrralList) {
    const list = document.querySelector(".list");
    let listElements = list.querySelectorAll('li');
    let listInfo = document.querySelectorAll(".info-container");
    let screenSize = window.innerWidth;


    referrralList.addEventListener("mouseenter", e => {
        cursor.classList.add("pale-green");
    });
    
    referrralList.addEventListener("mouseleave", e => {
        cursor.classList.remove("pale-green");
    });
    
    window.addEventListener("load", (event) => {
        const unordedList = list.querySelector(".industry-big-list.middle");
        let listHeight = unordedList.offsetHeight/2 - referrralList.offsetHeight/2;
        list.scrollTop = listHeight;

        if(screenSize > 900) {
            activateMiddleItem(listElements, listInfo);
        } else {
            activateFirstItem(listElements, listInfo);
        }
    });

    for(let i=0; i<listElements.length; i++) {
        listElements[i].addEventListener("click", function(e) {
            listElements[currentActive].classList.remove('active');
            listInfo[currentActive].classList.remove('active');
            listInfo[currentActive].classList.remove('display');

            listElements[i].classList.add("active");
            listInfo[i].classList.add('display');
            setTimeout(function() {
                listInfo[i].classList.add('active');
            }, 100);
            currentActive = i;
        });
    }
}

function activateMiddleItem(listElements, listInfo) {
    let middleItem = Math.floor(listElements.length/2);
    listElements[middleItem].classList.add("active");
    listInfo[middleItem].classList.add('display');
    setTimeout(function() {
        listInfo[middleItem].classList.add('active');
    }, 100);
    currentActive = middleItem;
}

function activateFirstItem(listElements, listInfo) {
    listElements[0].classList.add("active");
    listInfo[0].classList.add('display');
    setTimeout(function() {
        listInfo[0].classList.add('active');
    }, 100);
    currentActive = 0;
}
