import { moveGlide } from './_image-slider-configuration.js';

let slider = document.querySelector('#image-slider');
let cursor = document.querySelector("#cursor");
let sliderItems = null;
let arrowField = null;

if(slider) {    
    sliderItems = slider.querySelectorAll('.glide__slide');
    arrowField = slider.querySelectorAll(".cursor-field");

    for (let i = 0; i < sliderItems.length; i++) {

        arrowField[i].addEventListener('mouseover', function(event) {
            if(sliderItems[i].classList.contains("glide__slide--active")){
                appendCursorField('← Prev', cursor)
            } else {
                appendCursorField('Next →', cursor)
            }
        });

        arrowField[i].addEventListener('mouseout', function(event) {
            removeCursorField(cursor)
        });

        arrowField[i].addEventListener('click', function(event) {
            if(sliderItems[i].classList.contains("glide__slide--active")){
                moveGlide('<');
            } else {
                moveGlide('>');
            }
        });
    };
}

function appendCursorField(txt, cursor) {
    cursor.innerHTML = '';
    let textBox = document.createElement("p");
    textBox.classList.add('cursor-text');
    textBox.innerText = txt;    
    cursor.append(textBox);

    let width = textBox.offsetWidth + 45;
    cursor.style.width = width+'px';
    cursor.style.left = '-60px';

    cursor.classList.add('active');
}

function removeCursorField(cursor) {
    cursor.innerHTML = '';
    cursor.style.width = '18px'
    cursor.classList.remove('active');
}