let slideshow = document.querySelector(".slideshow");
let slideImages = null;
let slideInfo = null;
let slideText = null;
let prevSlide = 0;
let activeSlide = 1;
let duration = 5000;
let screenSizeBig = false;

if(slideshow) {
    let slideshowInfoContainer = slideshow.querySelector(".slideshow-info");
    slideImages = slideshow.querySelectorAll(".slide-image");
    slideInfo = slideshow.querySelectorAll(".slide");
    slideText = slideshow.querySelectorAll(".slide-text");
    slideText[0].style.maxHeight = slideText[0].scrollHeight +'px';
    
    let nextArrow = slideshow.querySelector(".slide_arrow .next");
    let prevArrow = slideshow.querySelector(".slide_arrow .prev");
    
    
    let animation = setInterval(loopAnimation, duration);
    slideshowInfoContainer.style.minHeight = slideshowInfoContainer.scrollHeight + slideText[0].scrollHeight + 50 +'px';


    let screenWidth = document.body.clientWidth;
    
    if (screenWidth >= 900){
        screenSizeBig = true;
    } else {
        screenSizeBig = false;
    }

    nextArrow.addEventListener('click', function(event) {     
        clearInterval(animation);
        clearAll();
        slideImages[activeSlide].classList.add("active");
        slideInfo[activeSlide].classList.add("active");
        slideText[activeSlide].style.maxHeight = slideText[activeSlide].scrollHeight +'px';

        slideImages[prevSlide].classList.remove("active");
        slideText[prevSlide].style.maxHeight = '0px';
        slideInfo[prevSlide].classList.remove("active");

        if(activeSlide >= slideImages.length-1) {
            activeSlide = 0;
            prevSlide = 2;
        } else {
            prevSlide = activeSlide;
            activeSlide++;
        }
        animation = setInterval(loopAnimation, duration);
    });

    prevArrow.addEventListener('click', function(event) {     
        clearInterval(animation);
        clearAll();
        if(prevSlide <= 0) {
            activeSlide = 0;
            prevSlide = 2;
        } else {
            activeSlide = prevSlide;
            prevSlide--;
        }
        slideImages[prevSlide].classList.add("active");
        slideInfo[prevSlide].classList.add("active");
        slideText[prevSlide].style.maxHeight = slideText[prevSlide].scrollHeight +'px';
    });


    if(screenSizeBig == true) {
        for (let i = 0; i < slideImages.length; i++) {
            slideInfo[i].addEventListener('mouseover', function(event) {     
                clearInterval(animation);
                clearAll();
                slideImages[i].classList.add('hover');
                slideText[i].style.maxHeight = slideText[activeSlide].scrollHeight +'px';
                slideInfo[i].classList.add('hover');
            });
    
            slideInfo[i].addEventListener('mouseout', function(event) {    
                slideImages[i].classList.remove("active", 'hover');
                slideInfo[i].classList.remove("active", 'hover');
                slideText[i].style.maxHeight = '0px';
                slideText[prevSlide].style.maxHeight = slideText[activeSlide].scrollHeight +'px';
                slideImages[prevSlide].classList.add("active");
                slideInfo[prevSlide].classList.add("active");
                animation = setInterval(loopAnimation, duration);
            });
        }
    }
}


function loopAnimation() {
    slideImages[activeSlide].classList.add("active");
    slideInfo[activeSlide].classList.add("active");
    slideText[activeSlide].style.maxHeight = slideText[activeSlide].scrollHeight +'px';


    slideImages[prevSlide].classList.remove("active");
    slideText[prevSlide].style.maxHeight = '0px';
    slideInfo[prevSlide].classList.remove("active");


    if(activeSlide >= slideImages.length-1) {
        activeSlide = 0;
        prevSlide = 2;
    } else {
        prevSlide = activeSlide;
        activeSlide++;
    }
}

function clearAll() {
    for (let i = 0; i < slideImages.length; i++) {
        slideImages[i].classList.remove("active", 'hover');
        slideText[i].style.maxHeight = '0px';
        slideInfo[i].classList.remove("active", 'hover');
    }
}