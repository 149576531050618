let cursor = document.querySelector("#cursor");
let partners = document.querySelector(".partners");

if(partners) {
    partners.addEventListener("mouseenter", e => {
        cursor.classList.add("pale-green");
    });
    
    partners.addEventListener("mouseleave", e => {
        cursor.classList.remove("pale-green");
    });
}