import { moveGlide } from './_post-image-slider-configuration.js';

let slider = document.querySelector("#post-image-slider");
let cursor = document.querySelector("#cursor");
let sliderItems = null;

if(slider) {
    sliderItems = slider.querySelectorAll('.glide__slide');
    let sliderContent = slider.querySelectorAll('.slider-content');
    let fieldNext = document.querySelectorAll(".cursor-next-field");
    let fieldPrev = document.querySelectorAll(".cursor-prev-field");
    let totalSlides = fieldNext.length;

    for (let i = 0; i < sliderItems.length; i++) {

        sliderContent[i].addEventListener('mouseover', function(event) {
            let txt = i+1 + '/' + totalSlides;
            appendCursorField(txt, cursor)
        });

        sliderContent[i].addEventListener('mouseout', function(event) {
            removeCursorField(sliderContent[i], cursor)
        });


        fieldNext[i].addEventListener('mouseover', function(event) {
            let txt = i+1 + '/' + totalSlides + ' →';
            appendCursorField(txt, cursor)
        });

        fieldNext[i].addEventListener('mouseout', function(event) {
            removeCursorField(fieldNext[i], cursor)
        });

        fieldPrev[i].addEventListener('mouseover', function(event) {
            let txt = '← ' + (i+1) + '/' + totalSlides;
            appendCursorField(txt, cursor)
        });

        fieldPrev[i].addEventListener('mouseout', function(event) {
            removeCursorField(fieldNext[i], cursor)
        });

        fieldNext[i].addEventListener('click', function(event) {
            moveGlide('>');
        });

        fieldPrev[i].addEventListener('click', function(event) {
            moveGlide('<');
        });
    };
}

function appendCursorField(txt, cursor) {
    cursor.innerHTML = '';
    let textBox = document.createElement("p");
    textBox.classList.add('cursor-text');
    textBox.innerText = txt;    
    cursor.append(textBox);

    let width = textBox.offsetWidth + 45;
    cursor.style.width = width+'px';
    cursor.style.left = '-60px';

    cursor.classList.add('active');
}

function removeCursorField(field, cursor) {
    cursor.innerHTML = '';
    cursor.style.width = '18px'
    cursor.classList.remove('active');
}
