import { moveGlide } from './_slider-configuration.js';

let slider = document.querySelectorAll('.slider-component');
let cursor = document.querySelector("#cursor");
let arrowNextField = null;

if(slider) { 
    for(let y=0; y<slider.length;y++){
        let greenSlider = document.querySelector(".slider.green");
        let nextButton = slider[y].querySelector(".glide__arrows button.next");
        let prevButton = slider[y].querySelector(".glide__arrows button.prev");
        let sliderItems = slider[y].querySelectorAll('.glide__slide');
        arrowNextField = slider[y].querySelectorAll(".cursor-next-field");
        
        if(greenSlider) {
            greenSlider.addEventListener("mouseenter", e => {
                cursor.classList.add("pale-green");
            });
            
            greenSlider.addEventListener("mouseleave", e => {
                cursor.classList.remove("pale-green");
            });
        }
        
        for (let i = 0; i < sliderItems.length; i++) {
            let sliderContent = sliderItems[i].querySelector(".slider-content");

            sliderContent.addEventListener('mouseover', function(event) {
                let hovertext = ''; 
                if(sliderItems[i].dataset.hovertext == '') {
                    hovertext = 'Read more ↗';    
                } else {
                    hovertext = sliderItems[i].dataset.hovertext +' ↗';
                }
                
                appendCursorText(cursor, hovertext);
            });
    
            sliderContent.addEventListener('mouseout', function(event) {
                removeCursorText(cursor);
            });
    
            arrowNextField[i].addEventListener('mouseover', function(event) {
                appendCursorField(sliderItems[i], cursor, i)
            });
    
            arrowNextField[i].addEventListener('mouseout', function(event) {
                removeCursorField(sliderItems[i], cursor)
            });
    
            arrowNextField[i].addEventListener('click', function(event) {
                if(sliderItems[i].classList.contains("glide__slide--active")){
                    prevButton.click();
                } else {
                    nextButton.click();
                }
            });
        };
    }   
}

function appendCursorText(cursor, txt) {
    let textBox = document.createElement("p");
    textBox.innerText = txt;
    textBox.classList.add('cursor-text');
    
    cursor.innerHTML = '';
    cursor.append(textBox);
    
    let width = textBox.offsetWidth + 45;
    cursor.style.width = width+'px';
    cursor.style.left = '-60px';

    cursor.classList.add('active');
}

function removeCursorText(cursor) {
    cursor.innerHTML = '';
    cursor.style.width = '18px'
    cursor.classList.remove('active');
}


function appendCursorField(sliderItem, cursor, index) {
    cursor.innerHTML = '';
    let textBox = document.createElement("p");
    textBox.classList.add('cursor-text');

    if(sliderItem.classList.contains("glide__slide--active")){
        textBox.innerText = "← Prev";
    } else {
        textBox.innerText = "Next →";
    }
    
    cursor.append(textBox);
    
    let width = textBox.offsetWidth + 45;
    cursor.style.width = width+'px';
    cursor.style.left = '-60px';

    cursor.classList.add('active');
}

function removeCursorField(field, cursor) {
    cursor.innerHTML = '';
    cursor.style.width = '18px'
    cursor.classList.remove('active');
}