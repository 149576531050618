import Glide from "@glidejs/glide";
let glide1 = null;
let glide2 = null;

function initSlider1(id) {
    glide1 = new Glide('#' + id, {
        type: 'carousel',
        rewind: false, 
        bound: true,
        startAt: 0,
        gap: 20,
        perView: 1,
        breakpoints: {
            900: {
                perView: 1,
                gap: 10,
            }
        }
    });
    
    glide1.mount();
}

function initSlider2(id) {
    glide2 = new Glide('#' + id, {
        type: 'carousel',
        rewind: false, 
        bound: true,
        startAt: 0,
        gap: 20,
        perView: 1,
        breakpoints: {
            900: {
                perView: 1,
                gap: 10,
            }
        }
    });
    
    glide2.mount();
}

export function moveGlide1(whichWay) {
    glide1.go(whichWay);
}

export function moveGlide2(whichWay) {
    glide2.go(whichWay);
}

window.initSliderOfficeOne = initSlider1;
window.initSliderOfficeTwo = initSlider2;
